import { call, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from "@reduxjs/toolkit";
import { IHttpError } from "types/http";
import UserService from "modules/users/services/userService";
import {
  IUsersPayload,
  loadUsers, loadUsersSuccess, loadUsersFailed,
  loadUser, loadUserSuccess, loadUserFailed,
} from "./usersSlice";

function* handleLoadUsers(action: PayloadAction<IUsersPayload>): any {
  try {
    const users = yield call(UserService.getUsers, action.payload);
    yield put(loadUsersSuccess(users));
  } catch (error) {
    yield put(loadUsersFailed(error as IHttpError));
  }
}

function* handleLoadUser(action: PayloadAction<string>): any {
  try {
    const user = yield call(UserService.getUser, action.payload);
    yield put(loadUserSuccess(user));
  } catch (error) {
    yield put(loadUserFailed(error as IHttpError));
  }
}

export default function* usersSaga() {
  yield takeLatest(loadUsers.toString(), handleLoadUsers);
  yield takeLatest(loadUser.toString(), handleLoadUser);
}
