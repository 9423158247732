import { alpha } from '@mui/material/styles';
import { EnhancedTableToolbarProps } from "./index";
import {
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected, fromPage } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {props.title}
                </Typography>
            )}
            {numSelected > 0 && fromPage !== "notification" ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                props?.toolbarActions
            )}
            {numSelected > 0 && fromPage === "notification" &&
                <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={props.onResendNotification}
                >
                    Resend
                </Button>
            }
        </Toolbar>
    );
}
