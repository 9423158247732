import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useRouterUtil } from "utils/router";

export default function EditUserPage() {
    const { gotoLink } = useRouterUtil();

    return <div>
        <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={(event) => gotoLink(event, "/admin/events")} href="#">
                    Events
                </Link>
                <Typography color="text.primary">Edit Event</Typography>
            </Breadcrumbs>
        </div>

        Edit Event Page

    </div>
}