import axiosClient from "utils/axiosClient.util";
import {
  INotificationPayload,
  IMessages,
} from "modules/notification/store/notificationSlice";

const NotificationService = {
  getErrorsZNSNotification(params: INotificationPayload): Promise<IMessages> {
    return axiosClient.post("api/Notification/v1/messages", { ...params })
  },
  resendNotificationMessages(params: any): Promise<any> {
    return axiosClient.post("api/Notification/v1/resend-messages", { notificationMessageIds: params })
  },
}

export default NotificationService
