import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRouterUtil } from "utils/router";
import {
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  MenuItem,
  Paper,
  Alert,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IUser } from "modules/users/types";

const userDefault: IUser = {
  username: "",
  fullName: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  roles: ["Administrator"],
  status: "Active",
};

export default function UserForm(props: any) {
  const { mode, userData, userError, handleFormSubmit } = props;
  const { goBack } = useRouterUtil();

  const { control, handleSubmit, formState: { isDirty, isValid }, setError, clearErrors, watch } = useForm({
    defaultValues: mode === "edit" ? userData : userDefault,
    mode: "onChange",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const validationRules = {
    username: {
      required: 'Username is required',
    },
    password: {
      required: 'Password is required',
      validate: (value: string) => {
        if (value.length < 8) {
          return "Password should be at-least 8 characters";
        }
        if (!value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[!@#$*])/)) {
          return "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol";
        }
        if (watch("confirmPassword") !== "") {
          if (value !== watch("confirmPassword")) {
            setError("confirmPassword", { message: 'Confirm password is not matched' });
          } else { clearErrors("confirmPassword"); }
        }
        return true;
      },
    },
    confirmPassword: {
      required: 'Confirm password is required',
      validate: (value: string) => {
        if (value !== watch("password")) {
          return "Confirm password is not matched";
        }
        return true;
      }
    },
    phoneNumber: {
      validate: (value: string) => {
        if (value && !value.match(/^0\d{9,10}$/)) {
          return "Phone number should start with 0 and have 9 - 10 digits";
        }
        return true;
      }
    }
  };

  const handleShowPassword = (name: string, value: boolean) => {
    setShowPassword({
      ...showPassword,
      [name]: value,
    });
  };

  const onSubmit = (data: IUser) => {
    handleFormSubmit(data);
  };

  return (
    <>
      <Paper sx={{ width: '100%', p: 2 }}>
        <div className="pg-header">
          <ArrowBackIosIcon className="arrow-back" onClick={goBack} />
          <Typography className="pg-header__title" variant="h6">
            {mode === "edit" ? "Update Administrator" : "New Administrator"}
          </Typography>
        </div>
        <form className="frm" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            {userError &&
              <Grid xs={12} sx={{ mb: 1 }}>
                <Alert severity="error">
                  {userError.Message || "System error, please try again later"}
                </Alert>
              </Grid>
            }
            <Grid xs={12} md={6}>
              <Controller
                name="username"
                control={control}
                rules={validationRules.username}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    type="text"
                    label="Username"
                    InputProps={{
                      readOnly: mode === "edit",
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    label="Full name"
                  />
                )}
              />
            </Grid>
            {mode !== "edit" &&
              <>
                <Grid xs={12} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={validationRules.password}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        type={showPassword.password ? 'text' : 'password'}
                        label="Password"
                        autoComplete="password"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleShowPassword("password", !showPassword.password)}
                                edge="end"
                              >
                                {showPassword.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>,
                        }}
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={validationRules.confirmPassword}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        type={showPassword.confirmPassword ? 'text' : 'password'}
                        label="Confirm password"
                        autoComplete="confirm-password"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleShowPassword("confirmPassword", !showPassword.confirmPassword)}
                                edge="end"
                              >
                                {showPassword.confirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>,
                        }}
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </>
            }
            <Grid xs={12} md={6}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={validationRules.phoneNumber}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="tel"
                    label="Phone number"
                    InputProps={{
                      readOnly: mode === "edit",
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      {...field}
                      labelId="status-label"
                      label="Status"
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Button variant="contained" color="primary" type="submit" disabled={!isDirty || !isValid}>
                {mode === "edit" ? "Update User" : "Add New User"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}