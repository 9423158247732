import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IUser } from "modules/users/types";
import { IHttpError } from "types/http";

export interface IUsersPayload {
  roleNames?: any;
  status?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface IUsers {
  results: IUser[];
  totalCount: number;
}

export interface IState {
  loading?: boolean;
  data?: IUsers;
  selectedUser?: IUser;
  error?: IHttpError;
}

const initialState: IState = {
  loading: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loadUsers: (state, _action: PayloadAction<IUsersPayload>) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    },
    loadUsersSuccess: (state, action: PayloadAction<IUsers>) => {
      state.loading = false;
      state.data = action.payload;
    },
    loadUsersFailed: (state, action: PayloadAction<IHttpError>) => {
      state.loading = false;
      state.error = action.payload;
    },
    loadUser: (state, _action) => {
      state.loading = true;
      state.selectedUser = undefined;
      state.error = undefined;
    },
    loadUserSuccess: (state, action: PayloadAction<IUser>) => {
      state.loading = false;
      state.selectedUser = action.payload;
    },
    loadUserFailed: (state, action: PayloadAction<IHttpError>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadUsers,
  loadUsersSuccess,
  loadUsersFailed,
  loadUser,
  loadUserSuccess,
  loadUserFailed,
} = usersSlice.actions;

export const selectUsers = (state: RootState) => state.users?.data;
export const selectTotalCount = (state: RootState) =>
  state.users?.data?.totalCount;
export const selectUser = (state: RootState) => state.users?.selectedUser;
export const selectUserPageCount = (state: RootState) =>
  state.users?.data?.results?.length;

export default usersSlice.reducer;
