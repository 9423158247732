import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';
import { saveAs } from 'file-saver';
import LoyaltyService from "modules/loyalty/services/loyaltyService";

export default function ExportTheAuditReport() {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      fromDate: moment(),
      toDate: moment(),
    },
    mode: "onChange",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleExportTheAuditReport = (data: any) => {
    setIsLoading(true);
    LoyaltyService.exportAuditReport(data)
      .then((response: any) => {
        const file = new Blob([response], { type: 'text/csv;charset=utf-8' });
        const filename = `lucky_draw_winner_audit_from_${moment(data?.fromDate).format("YYYY/MM/DD")}_to_${moment(data?.toDate).format("YYYY/MM/DD")}.csv`;

        saveAs(file, filename);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <form className="frm" onSubmit={handleSubmit(handleExportTheAuditReport)} noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name="fromDate"
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    label="From Date"
                    componentsProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined',
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message
                      }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name="toDate"
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    label="To Date"
                    componentsProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined',
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message
                      }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12}>
            <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
              Export
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
