import * as React from "react";
import { useEffect } from "react";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useMatches,
} from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import _last from "lodash-es/last";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "./listItems";
import UserMenu from "./UserMenu";
import Copyright from "components/Common/Copyright";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectCurrentUser,
  loadCurrentUser,
  selectLoadingCurrentUser,
} from "modules/auth/store/authSlice";
import { API_TOKEN_KEY } from "constants/config";
import FullLoading from "components/Common/FullLoading";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const [pageTitle, setPageTitle] = React.useState();
  const apiToken = localStorage.getItem(API_TOKEN_KEY);
  const currentUser = useAppSelector(selectCurrentUser);
  const isLoadingCurrentUser = useAppSelector(selectLoadingCurrentUser);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const routeMatches = useMatches();
  const routeData = _last(routeMatches) as any;

  const onLoadCurrentUser = () => {
    dispatch(loadCurrentUser());
  };

  useEffect(() => {
    setPageTitle(routeData?.data?.title);
  }, [routeData?.data?.title]);

  useEffect(() => {
    if (apiToken && !currentUser && !isLoadingCurrentUser) {
      onLoadCurrentUser();
    }
  });

  if (!currentUser && !apiToken) {
    return <Navigate to="/login" replace />;
  }

  if (!currentUser && apiToken) {
    return <FullLoading />;
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {pageTitle}
            </Typography>

            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <UserMenu icon={<AccountCircleIcon />} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {currentUser?.requiredChangePassword &&
            !location.pathname.startsWith("/admin/profile/change-password") && (
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: (theme) => theme.palette.error.main,
                  color: (theme) => theme.palette.error.contrastText,
                  fontWeight: "bold",
                }}
              >
                Your password has expired and must be changed. Please go
                to&nbsp;
                <Link to="/admin/profile/change-password">Change Password</Link>
                &nbsp;to change your password
              </Toolbar>
            )}

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export function AdminLayout(props: any) {
  return <DashboardContent {...props} />;
}
