import { useEffect, useState } from "react";
import {
  storyblokInit,
  renderRichText,
  apiPlugin,
  getStoryblokApi,
} from "@storyblok/react";
import Loading from "components/Common/Loading";

storyblokInit({
  accessToken: "0hfg5CwmrelP8fJT5llmWAtt",
  use: [apiPlugin],
});
const storyblokApi = getStoryblokApi();

export default function StoryBlokPage() {
  const [homePageHtml, setHomePageHtml] = useState<any>();

  const fetchHomePageData = () => {
    storyblokApi
      .get("cdn/stories/homepage", { version: "published" })
      .then((response) => {
        const renderedRichText = renderRichText(
          response.data.story.content.body
        );

        setHomePageHtml(renderedRichText);
      });
  };

  useEffect(() => {
    fetchHomePageData();
  }, []);

  if (!homePageHtml) {
    return <Loading />;
  }

  return <div dangerouslySetInnerHTML={{ __html: homePageHtml }}></div>;
}
