import { useState, cloneElement, ReactElement } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

export type Order = "asc" | "desc";

export interface IHeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

export interface ITablePaging {
  total: number;
  page: number;
  pages: number;
  limit: number;
}

export interface EnhancedTableHeadProps {
  headCells: IHeadCell[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  showCheckBox?: boolean;
  sortable?: boolean;
}

export interface EnhancedTableToolbarProps {
  fromPage?: string;
  title: string;
  numSelected: number;
  toolbarActions?: ReactElement;
  showCheckBox?: boolean;
  showFilter?: boolean;
  onResendNotification?: (data: any) => void;
}

export interface IDataTableProps {
  fromPage?: string;
  name?: string;
  title: string;
  headCells: IHeadCell[];
  rows: any[];
  toolbarActions?: ReactElement;
  children?: ReactElement;
  showCheckBox?: boolean;
  showFilter?: boolean;
  sortable?: boolean;
  paging?: ITablePaging;
  onDelete?: (data: any) => void;
  onUpdate?: (data: any) => void;
  onResendNotification?: (data: any) => void;
  onPageChange?: (page: number) => void;
}

export interface IDataTableRow {
  item?: any;
  isItemSelected?: boolean;
  labelId?: string;
  onClick?: () => void;
  onDelete?: (data: any) => void;
  onUpdate?: (data: any) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function DataTable(props: IDataTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("calories");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    _event.stopPropagation();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n[`${props?.name}`]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, name: string) => {
    _event.stopPropagation();
    if (!props.showCheckBox) {
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage === props?.paging?.page) {
      return;
    }

    setPage(newPage);
    props.onPageChange && props.onPageChange(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        title={props.title}
        toolbarActions={props.toolbarActions}
        showCheckBox={props.showCheckBox}
        showFilter={props.showFilter}
        fromPage={props.fromPage}
        onResendNotification={() => props.onResendNotification && props.onResendNotification(selected)}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            headCells={props.headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
            sortable={props.sortable}
            showCheckBox={props.showCheckBox}
          />

          <TableBody>
            {stableSort(props.rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row[`${props?.name}`] as string);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row[`${props?.name}`] as string)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row[`${props?.name}`] || index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {props.children &&
                      cloneElement(props.children, {
                        key: `row-${index}`,
                        item: row,
                        isItemSelected,
                        labelId,
                        onClick: handleClick,
                        onDelete: props.onDelete,
                        onUpdate: props.onUpdate,
                      })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {props.paging && (
        <Stack spacing={2} padding={2} alignItems="end">
          <Pagination
            page={props.paging.page}
            count={props.paging.pages}
            onChange={handleChangePage}
          />
        </Stack>
      )}
    </>
  );
}
