import NotificationPage from "modules/notification/pages/NotificationPage";

const router = [
  {
    path: "notification",
    element: <NotificationPage />,
    loader: () => ({ title: "Notification" }),
  }
];

export default router;
