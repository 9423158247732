import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch } from "app/hooks";
import { logout } from "modules/auth/store/authSlice";

import DropdownMenu, {
  IDropdownMenuProps,
} from "components/Common/DropdownMenu";
import { Link } from "react-router-dom";

export interface IUserMenuContentProps {
  handleClose?: () => void;
}

const UserMenuContent = (props: IUserMenuContentProps) => {
  const dispatch = useAppDispatch();
  const onClickLogout = () => {
    props.handleClose && props.handleClose();
    dispatch(logout());
  };

  return (
    <div>
      {/* <MenuItem onClick={props.handleClose}>Profile</MenuItem>
        <MenuItem onClick={props.handleClose}>My account</MenuItem> */}
      <MenuItem
        component={Link}
        to="/admin/profile/change-password"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        onClick={() => {
          props.handleClose && props.handleClose();
        }}
      >
        Change Password
      </MenuItem>
      <MenuItem onClick={onClickLogout}>Logout</MenuItem>
    </div>
  );
};

export default function UserMenu(props: IDropdownMenuProps) {
  return (
    <DropdownMenu icon={props.icon}>
      <UserMenuContent />
    </DropdownMenu>
  );
}
