import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { API_TOKEN_EXPIRED_KEY } from "constants/config";
import {
  ILoginPayload,
  logout,
  login,
  loginSuccess,
  loginFailed,
  loadCurrentUser,
  setCurrentUser,
  changePasswordUser,
  changePasswordUserFailed,
  changePasswordUserSuccess,
} from "./authSlice";
import AuthService from "../services/authService";
import { setAuthInfo, clearAuthInfo } from "utils/auth.util";
import { IHttpError } from "types/http";
let refreshTokenInterval: any = undefined;

function* refreshToken() {
  yield AuthService.refreshToken().then((_response) => {
    setAuthInfo(_response);
  });
}

function handleLogout() {
  clearAuthInfo();
  refreshTokenInterval && clearInterval(refreshTokenInterval);
}

function* handleLogin(payload: ILoginPayload): any {
  try {
    const response = yield call(AuthService.login, payload);
    yield setAuthInfo(response);
    yield handleLoadCurrentUser();
    yield put(loginSuccess());
  } catch (error) {
    yield put(loginFailed(error as IHttpError));
  }
}

function* handleLoadCurrentUser(): any {
  try {
    yield call(refreshToken);
    const currentUser = yield call(AuthService.loadCurrentUser);
    yield put(setCurrentUser(currentUser));
    const tokenExpiredTime = localStorage.getItem(API_TOKEN_EXPIRED_KEY);

    if (tokenExpiredTime) {
      refreshTokenInterval = setInterval(() => {
        refreshToken().next();
      }, parseInt(tokenExpiredTime) * 1000 - 10);
    }
  } catch (error: any) {
    if (error?.error === "invalid_grant") {
      yield put(logout());
      window.location.reload();
    }
  }
}

function* watchLoginFlow() {
  while (true) {
    const action: PayloadAction<ILoginPayload> = yield take(login.toString());
    yield fork(handleLogin, action.payload);
  }
}

function* handleChangePasswordUser({
  payload,
}: {
  type: string;
  payload: any;
}): any {
  try {
    yield call(refreshToken);
    const response = yield call(AuthService.changePasswordUser, payload);
    if (response.isSuccess) {
      yield put(changePasswordUserSuccess({}));
    } else {
      yield put(
        changePasswordUserFailed({
          Message:
            response?.Message ??
            response?.error?.message ??
            response.title ??
            "Error when update password",
        })
      );
    }
  } catch (error: any) {
    yield put(
      changePasswordUserFailed({
        Message:
          error.Message ??
          error.message ??
          error.statusText ??
          "Error when update password",
      })
    );
  }
}

export default function* authSaga() {
  yield takeEvery(logout.toString(), handleLogout);
  yield takeLatest(changePasswordUser.toString(), handleChangePasswordUser);
  yield takeLatest(loadCurrentUser.toString(), handleLoadCurrentUser);
  yield fork(watchLoginFlow);
}
