import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IUser } from "modules/users/types";
import { IHttpError } from "types/http";

export interface IAuthState {
  currentUser?: IUser;
  error?: IHttpError;
  logging: boolean;
  changePasswordProgress: boolean;
  loadingCurrentUser: boolean;
}

export interface ILoginPayload {
  username: string;
  password: string;
}

const initialState: IAuthState = {
  currentUser: undefined,
  logging: false,
  loadingCurrentUser: false,
  changePasswordProgress: false,
};

export interface IChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUser>) => {
      state.loadingCurrentUser = false;
      state.currentUser = action.payload;
    },
    logout: (state) => {
      state.currentUser = undefined;
      state.error = undefined;
    },
    login: (state, _action: PayloadAction<ILoginPayload>) => {
      state.logging = true;
      state.error = undefined;
    },
    loginSuccess: (state) => {
      state.logging = false;
      state.error = undefined;
    },
    loginFailed: (state, action: PayloadAction<IHttpError>) => {
      state.logging = false;
      state.error = action.payload;
    },
    loadCurrentUser: (state) => {
      state.loadingCurrentUser = true;
    },
    changePasswordUser: (
      state,
      action: PayloadAction<IChangePasswordPayload>
    ) => {
      state.changePasswordProgress = true;
      state.error = undefined;
    },
    changePasswordUserSuccess: (state, action: PayloadAction<any>) => {
      state.changePasswordProgress = false;
      state.error = undefined;
    },
    changePasswordUserFailed: (state, action: PayloadAction<any>) => {
      state.changePasswordProgress = false;
      state.error = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  logout,
  login,
  loginSuccess,
  loginFailed,
  loadCurrentUser,
  changePasswordUser,
  changePasswordUserFailed,
  changePasswordUserSuccess,
} = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;
export const selectLoginError = (state: RootState) => state.auth.error;
export const selectLogging = (state: RootState) => state.auth.logging;
export const selectLoadingCurrentUser = (state: RootState) =>
  state.auth.loadingCurrentUser;
export const selectChangePasswordProgress = (state: RootState) =>
  state.auth.changePasswordProgress;

export default authSlice.reducer;
