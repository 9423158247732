import authSaga from "modules/auth/store/authSaga";
import usersSaga from "modules/users/store/usersSaga";
import notificationSaga from "modules/notification/store/notificationSaga";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
    yield all([
        authSaga(), 
        usersSaga(),
        notificationSaga(),
    ])
}
