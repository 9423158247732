import { useState } from 'react';
import { useParams } from "react-router-dom";
import { useRouterUtil } from 'utils/router';
import UserService from "modules/users/services/userService";
import { ConfirmModal } from "components/Common";
import AdministratorForm from "./AdministratorForm";
import ScannerForm from "./ScannerForm";

export default function AddUserPage() {
  let { role } = useParams();
  const { goBack } = useRouterUtil();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errors, setErrors] = useState(null);

  const createUser = (user: any) => {
    UserService.addUser(user)
      .then(() => {
        setErrors(null);
        setOpenConfirmModal(true);
      })
      .catch((error) => {
        setErrors(error);
      })
  };

  const confirmModal = () => {
    setOpenConfirmModal(false);
    goBack();
  };

  return (
    <>
      {role === "administrator" &&
        <AdministratorForm
          userError={errors}
          handleFormSubmit={createUser}
        />
      }

      {role === "scanner" &&
        <ScannerForm
          userError={errors}
          handleFormSubmit={createUser}
        />
      }

      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        content={"User created successfully"}
        onConfirm={confirmModal}
      />
    </>
  )
}
