import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_API_URL, API_TOKEN_KEY } from "constants/config";
import { clearAuthInfo } from "utils/auth.util";

const axiosClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    // Do something before request is sent
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      API_TOKEN_KEY
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error?.response?.status === 401) {
      clearAuthInfo();
      window.location.reload();
      return Promise.reject(error);
    }

    return Promise.reject(error.response.data || error.response);
  }
);

export default axiosClient;
