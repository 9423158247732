import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useForm, Controller } from "react-hook-form";
import React, { useCallback, useEffect, useState } from "react";
import { useRouterUtil } from "utils/router";
import Grid from "@mui/material/Unstable_Grid2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  changePasswordUser,
  logout,
  selectChangePasswordProgress,
  selectLoginError,
} from "../store/authSlice";
import { ConfirmModal } from "components/Common";

const changePasswordDefault = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

const ChangePasswordPage = () => {
  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: changePasswordDefault,
    mode: "onChange",
  });

  const loginError = useAppSelector(selectLoginError);
  const changePasswordProgress = useAppSelector(selectChangePasswordProgress);
  const dispatch = useAppDispatch();
  const { goBack } = useRouterUtil();
  const [isFormSent, setIsFormSent] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });

  const onSubmit = useCallback(
    (data: any) => {
      setIsFormSent(true);
      setIsShowError(false);
      dispatch(
        changePasswordUser({
          currentPassword: data.currentPassword,
          newPassword: data.password,
        })
      );
    },
    [dispatch]
  );

  const validationRules = {
    currentPassword: {
      required: "Confirm password is required",
    },
    password: {
      required: "Password is required",
      validate: (value: string) => {
        if (value.length < 10) {
          return "Password at least 10 characters and contain one uppercase letter, one lowercase letter, one digit and one special character.";
        }
        if (
          !value.match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,20}/
          )
        ) {
          return "Password at least 10 characters and contain one uppercase letter, one lowercase letter, one digit and one special character.";
        }
        if (watch("confirmPassword") !== "") {
          if (value !== watch("confirmPassword")) {
            setError("confirmPassword", {
              message: "Confirm password is not matched",
            });
          } else {
            clearErrors("confirmPassword");
          }
        }
        return true;
      },
    },
    confirmPassword: {
      required: "Confirm password is required",
      validate: (value: string) => {
        if (value !== watch("password")) {
          return "Confirm password is not matched";
        }
        return true;
      },
    },
  };

  const handleShowPassword = (name: string, value: boolean) => {
    setShowPassword({
      ...showPassword,
      [name]: value,
    });
  };

  const confirmModal = () => {
    setOpenConfirmModal(false);
    dispatch(logout());
  };

  useEffect(() => {
    if (isFormSent && !changePasswordProgress && !loginError) {
      setIsFormSent(false);
      setOpenConfirmModal(true);
    }
  }, [changePasswordProgress, isFormSent, loginError]);

  useEffect(() => {
    if (isFormSent && loginError) {
      setIsFormSent(false);
      setIsShowError(true);
    }
  }, [isFormSent, loginError]);

  return (
    <>
      {changePasswordProgress && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme: { zIndex: { drawer: number } }) =>
              theme.zIndex.drawer + 1,
          }}
          open={changePasswordProgress}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Paper sx={{ width: "100%", p: 2 }}>
        <div className="pg-header">
          <ArrowBackIosIcon className="arrow-back" onClick={goBack} />
          <Typography className="pg-header__title" variant="h6">
            Change Password
          </Typography>
        </div>
        <form className="frm" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            {isShowError && (
              <Grid xs={12} sx={{ mb: 1 }}>
                <Alert severity="error">
                  {loginError?.Message ||
                    "System error, please try again later"}
                </Alert>
              </Grid>
            )}
            <Grid xs={12}>
              <Controller
                name="currentPassword"
                control={control}
                rules={validationRules.currentPassword}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    type={showPassword.currentPassword ? "text" : "password"}
                    label="Current Password"
                    autoComplete="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleShowPassword(
                                "currentPassword",
                                !showPassword.currentPassword
                              )
                            }
                            edge="end"
                          >
                            {showPassword.currentPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="password"
                control={control}
                rules={validationRules.password}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    type={showPassword.password ? "text" : "password"}
                    label="Password"
                    autoComplete="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleShowPassword(
                                "password",
                                !showPassword.password
                              )
                            }
                            edge="end"
                          >
                            {showPassword.password ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={validationRules.confirmPassword}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    type={showPassword.confirmPassword ? "text" : "password"}
                    label="Confirm password"
                    autoComplete="confirm-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleShowPassword(
                                "confirmPassword",
                                !showPassword.confirmPassword
                              )
                            }
                            edge="end"
                          >
                            {showPassword.confirmPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isDirty || !isValid}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        content={"Password changed successfully"}
        onConfirm={confirmModal}
      />
    </>
  );
};
export default React.memo(ChangePasswordPage);
