import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { MouseEvent } from 'react';

export interface IDataActionsMenuProps {
    onDelete: (event: MouseEvent) => void;
    onUpdate: (event: MouseEvent) => void;
}

export default function DataActionsMenu(props: IDataActionsMenuProps) {
    const onClick = (event: MouseEvent, actionName: "onDelete" | "onUpdate") => {
        event.stopPropagation();
        props[actionName](event);
    }

    return (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" startIcon={<DeleteIcon />} size="small" onClick={(event) => onClick(event, "onDelete")}>
                Delete
            </Button>
            <Button variant="contained" endIcon={<EditIcon />} size="small" onClick={(event) => onClick(event, "onUpdate")}>
                Update
            </Button>
        </Stack>
    );
}