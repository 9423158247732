import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import DataTable, { IHeadCell, IDataTableRow } from 'components/Common/DataTable';
import DataActionsMenu from 'components/Common/DataActionsMenu';
import { useRouterUtil } from 'utils/router';

interface Data {
    id: string | number;
    calories: number;
    carbs: number;
    fat: number;
    name: string;
    protein: number;
    actions: string;
}

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
): Data {
    return {
        id: Math.random(),
        name,
        calories,
        fat,
        carbs,
        protein,
        actions: "actions"
    };
}

const headCells: IHeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Dessert (100g serving)',
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Calories',
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Fat (g)',
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Carbs (g)',
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'Protein (g)',
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function ToolBar() {
    const { gotoLink } = useRouterUtil();

    return <>
        <Tooltip title="Filter list">
            <IconButton>
                <FilterListIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Add User">
            <IconButton onClick={(event) => gotoLink(event, "/admin/events/add")}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    </>
}

function UserRow(props: IDataTableRow) {
    return props.item && <>
        <TableCell padding="checkbox">
            <Checkbox
                color="primary"
                checked={props.isItemSelected}
                inputProps={{
                    'aria-labelledby': props.labelId,
                }}
            />
        </TableCell>
        <TableCell
            component="th"
            id={props.labelId}
            scope="row"
            padding="none"
        >
            {props.item.name}
        </TableCell>
        <TableCell align="right">{props.item.calories}</TableCell>
        <TableCell align="right">{props.item.fat}</TableCell>
        <TableCell align="right">{props.item.carbs}</TableCell>
        <TableCell align="right">{props.item.protein}</TableCell>
        <TableCell align="right">
            <DataActionsMenu
                onDelete={() => props.onDelete && props.onDelete(props.item)}
                onUpdate={() => props.onUpdate && props.onUpdate(props.item)}
            />
        </TableCell>
    </>
}

export default function UserPage() {
    const { navigate } = useRouterUtil();

    const onClickDeleteItem = (item: any) => {
        console.log("onClickDeleteItem", item)
    }

    const onClickEditItem = (item: any) => {
        console.log("onClickEditItem", item)
        navigate(`/admin/events/edit/${item.id}`)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <DataTable
                    headCells={headCells}
                    rows={rows} title="Events"
                    toolbarActions={<ToolBar />}
                    onDelete={onClickDeleteItem}
                    onUpdate={onClickEditItem}
                >
                    <UserRow />
                </DataTable>
            </Paper>
        </Box>
    );
}
