import { API_TOKEN_KEY } from "constants/config"
import { Navigate, RouteProps, Outlet } from "react-router-dom";

export function PrivateRoute({ children }: RouteProps) {
    const isLoggedin = localStorage.getItem(API_TOKEN_KEY);

    if (!isLoggedin) {
        return <Navigate to="/login" replace />
    }

    return children ? <>{children}</> : <Outlet />;
}
