import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useRouterUtil } from 'utils/router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loadUser, selectUser } from 'modules/users/store/usersSlice';
import UserService from "modules/users/services/userService";
import Loading from 'components/Common/Loading';
import { ConfirmModal } from "components/Common";
import AdministratorForm from "./AdministratorForm";
import ScannerForm from "./ScannerForm";

export default function EditUserPage() {
  let { role, username } = useParams();
  const { goBack } = useRouterUtil();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [errors, setErrors] = useState(null);

  const updateUser = (item: any) => {
    UserService.editUser(item)
      .then(() => {
        setErrors(null);
        setConfirmMessage("User updated successfully");
        setOpenConfirmModal(true);
      })
      .catch((error) => {
        setErrors(error);
      })
  };

  const confirmModal = () => {
    setOpenConfirmModal(false);
    goBack();
  };

  useEffect(() => {
    if (username) {
      dispatch(loadUser(username));
    }
  }, [username])

  if (!user) {
    return <Loading />
  }

  return (
    <>
      {role === "administrator" &&
        <AdministratorForm
          mode="edit"
          userData={user}
          userError={errors}
          handleFormSubmit={updateUser}
        />
      }

      {role === "scanner" &&
        <ScannerForm
          mode="edit"
          userData={user}
          userError={errors}
          handleFormSubmit={updateUser}
        />
      }

      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        content={confirmMessage}
        onConfirm={confirmModal}
      />
    </>
  )
}
