import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack } from '@mui/system';

export interface IInputFileProps {
    label: string;
}

export default function InputFile(props: IInputFileProps) {
    return <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
        <div>
            {props.label}:
        </div>
        <div>
            <input
                name="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
            />
            <label htmlFor="raised-button-file">
                <Button component="span" >
                    <CloudUploadIcon />
                </Button>
            </label>
        </div>
    </Stack>
}