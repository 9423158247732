import {
  API_TOKEN_KEY,
  API_REFRESH_TOKEN_KEY,
  API_TOKEN_EXPIRED_KEY,
} from "constants/config";

export function setAuthInfo(data: {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}) {
  localStorage.setItem(API_TOKEN_KEY, data.access_token);
  localStorage.setItem(API_REFRESH_TOKEN_KEY, data.refresh_token);
  localStorage.setItem(API_TOKEN_EXPIRED_KEY, data.expires_in.toString());
}

export function clearAuthInfo() {
  localStorage.removeItem(API_TOKEN_KEY);
  localStorage.removeItem(API_TOKEN_EXPIRED_KEY);
  localStorage.removeItem(API_REFRESH_TOKEN_KEY);
}
