import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { store } from "./app/store";

import { AdminLayout } from "components/Layout";
import { NotFound, PrivateRoute } from "components/Common";
import LoginPage from "modules/auth/pages/LoginPage";
import Dashboard from "components/Dashboard";
import UserRouter from "modules/users/router";
import EventRouter from "modules/events/router";
import LoyaltyRouter from "modules/loyalty/router";
import DemoRouter from "modules/demo/router";
import NotificationRouter from "modules/notification/router";
import ChangePasswordPage from "modules/auth/pages/ChangePasswordPage";

const container = document.getElementById("root")!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <AdminLayout />
      </PrivateRoute>
    ),
    loader: () => ({ title: "Admin" }),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        loader: () => ({ title: "Dashboard" }),
      },
      {
        path: "profile",
        children: [
          {
            path: "/admin/profile/change-password",
            element: <ChangePasswordPage />,
            loader: () => ({ title: "Change Password" }),
          },
        ],
      },
      ...DemoRouter,
      ...UserRouter,
      ...EventRouter,
      ...LoyaltyRouter,
      ...NotificationRouter,
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

root.render(
  <>
    <Provider store={store}>
      <CssBaseline />
      <RouterProvider router={router} />
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
