import EventPage from "modules/events/pages/EventPage";
import AddEventPage from "modules/events/pages/AddEventPage";
import EditEventPage from "modules/events/pages/EditEventPage";
const router = [
  {
    path: "events",
    element: <EventPage />,
    loader: () => ({ title: "Events" }),
  },
  {
    path: "/admin/events/add",
    element: <AddEventPage />,
    loader: () => ({ title: "Add Event" }),
  },
  {
    path: "/admin/events/edit/:id",
    element: <EditEventPage />,
    loader: () => ({ title: "Edit Event" }),
  },
];

export default router;
