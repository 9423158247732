import { getGlobalConfigValue } from "utils/config.util";

export const BASE_API_URL =
  getGlobalConfigValue("APP_BASE_API_URL") ||
  process.env.REACT_APP_BASE_API_URL;
export const CLIENT_ID =
  getGlobalConfigValue("APP_CLIENT_ID") || process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET =
  getGlobalConfigValue("APP_CLIENT_SECRET") ||
  process.env.REACT_APP_CLIENT_SECRET;
export const API_TOKEN_KEY = "jwt";
export const API_REFRESH_TOKEN_KEY = "API_REFRESH_TOKEN";
export const API_TOKEN_EXPIRED_KEY = "API_TOKEN_EXPIRED";
export const APP_NAME = "eKoin";
export const WEBSITE_LINK = "https://yourlink.com";
