import axiosClient from "utils/axiosClient.util"
import { IUser } from "modules/users/types";
import { IUsersPayload } from "modules/users/store/usersSlice";

const UserService = {
    getUsers(params: IUsersPayload): Promise<any> {
        return axiosClient.get("/api/UserManager/v1/users", { params })
    },
    getUser(username: any): Promise<any> {
        return axiosClient.get(`/api/UserManager/v1/user/${username}`)
    },
    addUser(params: IUser): Promise<any> {
        return axiosClient.post("/api/UserManager/v1/user", { ...params })
    },
    editUser(params: IUser): Promise<any> {
        return axiosClient.put("/api/UserManager/v1/user", { ...params })
    },
    deleteUser(username: any): Promise<any> {
        return axiosClient.delete(`/api/UserManager/v1/user/${username}`)
    }
}

export default UserService
