import { ChangeEvent, useState, useRef } from "react";
import { Typography, Button, Paper, InputBase } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import LoyaltyService from "modules/loyalty/services/loyaltyService";
import DescriptionIcon from "@mui/icons-material/Description";
import { CSVLink } from "react-csv";
import { ConfirmModal } from "components/Common";

interface ErrorsImport {
  error?: any;
  errors?: [
    {
      code?: string;
      prizeType?: 0;
      customerId?: string;
      luckyDrawRewardId?: string;
      rewardStatus?: 0;
      error?: string;
    }
  ];
}

const headers = [
  { label: "Code", key: "code" },
  { label: "Prize Type", key: "prizeType" },
  { label: "Error", key: "error" },
];

export default function ImportTheLuckyDrawWinner() {
  const fileInputRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [csvFile, setCsvFile] = useState<File>();
  const [errorsImport, setErrorsImport] = useState<ErrorsImport>();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setCsvFile(e.target.files[0]);
    }
  };

  const resetSelectedFile = () => {
    setCsvFile(undefined);

    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImportTheLuckyDraw = () => {
    if (!csvFile) {
      return;
    }

    setIsLoading(true);
    setErrorsImport(undefined);
    const formData = new FormData();
    formData.append("file", csvFile);

    LoyaltyService.importLuckyDrawWinner(formData)
      .then((response: any) => {
        if (!response.isSuccess) {
          setErrorsImport({
            errors: response?.errors,
          });
        }
        setIsLoading(false);
        setOpenConfirmModal(true);
        resetSelectedFile();
      })
      .catch((error) => {
        setErrorsImport({
          error: error,
        });
        setIsLoading(false);
        setOpenConfirmModal(true);
        resetSelectedFile();
      });
  };

  const confirmModal = () => {
    setOpenConfirmModal(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md={4}>
          <Paper
            component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Choose CSV file to import"
              value={(csvFile && csvFile.name) || ""}
              readOnly
            />
            <Button component="label" sx={{ p: "10px" }}>
              <DescriptionIcon />
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </Button>
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!csvFile || isLoading}
            onClick={handleImportTheLuckyDraw}
          >
            Import
          </Button>
        </Grid>
      </Grid>
      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onConfirm={confirmModal}
        backdropClick={true}
      >
        {!errorsImport && (
          <Typography>Import the lucky draw winner successfully</Typography>
        )}
        {errorsImport?.error && (
          <Typography color={"red"}>
            Import failed:{" "}
            {errorsImport?.error?.Message ||
              "System error, please try again later"}
          </Typography>
        )}
        {errorsImport?.errors && (
          <Typography color={"red"}>
            <span className="mr5 error">Import failed:</span>
            <CSVLink
              data={errorsImport.errors}
              headers={headers}
              filename={`lucky_draw_winner_errors_${moment().format(
                "YYYY/MM/DD"
              )}.csv`}
            >
              Download errors CSV
            </CSVLink>
          </Typography>
        )}
      </ConfirmModal>
    </>
  );
}
