import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useRouterUtil } from "utils/router";
import InputFile from 'components/Common/Form/InputFile';

export default function AddUserPage() {
    const { gotoLink } = useRouterUtil();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const _userData = {
            email: data.get('email'),
            password: data.get('password'),
            file: data.get('file'),
        }

        console.log("_userData ", _userData)
    };

    return <div>
        <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={(event) => gotoLink(event, "/admin/events")} href="#">
                    Events
                </Link>
                <Typography color="text.primary">Add Event</Typography>
            </Breadcrumbs>
        </div>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <InputFile label="Avatar" />
            <Stack alignContent={"flex-end"} justifyContent={"end"}>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Create
                </Button>
            </Stack>
        </Box>
    </div>
}