import axiosClient from "utils/axiosClient.util";
import { IUser } from "modules/users/types";
import { IChangePasswordPayload, ILoginPayload } from "../store/authSlice";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  API_REFRESH_TOKEN_KEY,
} from "constants/config";

const AuthService = {
  refreshToken(): Promise<{
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
  }> {
    return axiosClient.post(
      "/connect/token",
      {
        grant_type: "refresh_token",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        refresh_token: localStorage.getItem(API_REFRESH_TOKEN_KEY),
        scope: "offline_access",
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
  },
  login(account: ILoginPayload): Promise<{
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
  }> {
    return axiosClient.post(
      "/connect/token",
      {
        grant_type: "password",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        scope: "offline_access",
        ...account,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
  },
  loadCurrentUser(): Promise<IUser> {
    return axiosClient.get("/connect/userinfo");
  },
  changePasswordUser(params: IChangePasswordPayload): Promise<any> {
    return axiosClient.put("/api/UserManager/v1/user/password", params);
  },
};

export default AuthService;
