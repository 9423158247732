import { styled } from "@mui/material/styles";

interface LoadingComponentProps {
  variant?: "normal" | "grey";
}

const LoadingComponent = styled("div")<LoadingComponentProps>((props) => {
  if (props.variant === "grey") {
    return {
      color: "black",
      ".lds-dual-ring:after": {
        color: "black",
        border: "3px solid #9f9494",
        borderColor: "#9f9494 transparent #9f9494 transparent",
      },
    };
  }
});

export default function Loading(props: LoadingComponentProps) {
  return (
    <LoadingComponent
      className="loading-container"
      variant={props.variant || "grey"}
    >
      <div className="lds-dual-ring"></div>
    </LoadingComponent>
  );
}
