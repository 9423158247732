import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Alert,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowBackIos } from '@mui/icons-material';
import { useRouterUtil } from "utils/router";
import { IUser } from "modules/users/types";

const userDefault: IUser = {
  fullName: "",
  phoneNumber: "",
  roles: ["Scanner"],
  status: "Active",
};

export default function UserForm(props: any) {
  const { mode, userData, userError, handleFormSubmit } = props;
  const { goBack } = useRouterUtil();

  const { control, handleSubmit, formState: { isDirty, isValid } } = useForm({
    defaultValues: mode === "edit" ? userData : userDefault,
    mode: "onChange",
  });

  const validationRules = {
    phoneNumber: {
      required: 'Phone number is required',
      validate: (value: string) => {
        if (!value.match(/^0\d{9,10}$/)) {
          return "Phone number should start with 0 and have 9 - 10 digits";
        }
        return true;
      }
    }
  };

  const onSubmit = (data: IUser) => {
    handleFormSubmit(data);
  };

  return (
    <>
      <Paper sx={{ width: '100%', p: 2 }}>
        <div className="pg-header">
          <ArrowBackIos className="arrow-back" onClick={goBack} />
          <Typography className="pg-header__title" variant="h6">
            {mode === "edit" ? "Update Scanner" : "New Scanner"}
          </Typography>
        </div>
        <form className="frm" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            {userError &&
              <Grid xs={12} sx={{ mb: 1 }}>
                <Alert severity="error">
                  {userError.Message || "System error, please come back later"}
                </Alert>
              </Grid>
            }
            <Grid xs={12} md={6}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={validationRules.phoneNumber}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    type="tel"
                    label="Phone number"
                    InputProps={{
                      readOnly: mode === "edit",
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    label="Full name"
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      {...field}
                      labelId="status-label"
                      label="Status"
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Button variant="contained" color="primary" type="submit" disabled={!isValid || !isDirty}>
                {mode === "edit" ? "Update User" : "Add New User"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
