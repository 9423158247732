import LoyaltyPage from "modules/loyalty/pages/LoyaltyPage";

const router = [
  {
    path: "loyalty",
    element: <LoyaltyPage />,
    loader: () => ({ title: "Loyalty" }),
  },
];

export default router;
