import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from "@reduxjs/toolkit";
import { IHttpError } from "types/http";
import NotificationService from "modules/notification/services/notificationService";

import {
  INotificationPayload,
  loadErrorsZNSNotification,
  loadErrorsZNSNotificationSuccess,
  loadErrorsZNSNotificationFailed,
} from "./notificationSlice";

function* handleLoadErrorsZNSNotification(action: PayloadAction<INotificationPayload>): any {
  try {
    const messages = yield call(NotificationService.getErrorsZNSNotification, action.payload);
    yield put(loadErrorsZNSNotificationSuccess(messages));
  } catch (error) {
    yield put(loadErrorsZNSNotificationFailed(error as IHttpError));
  }
}

export default function* notificationSaga() {
  yield takeLatest(loadErrorsZNSNotification.toString(), handleLoadErrorsZNSNotification);
}
