import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  Collapse,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RedeemIcon from "@mui/icons-material/Redeem";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

export default function MainListItems() {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItemButton
        component={Link}
        to="/admin/dashboard"
        selected={location.pathname === "/admin/dashboard"}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {/* <ListItemButton>
            <ListItemIcon>
                <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
        </ListItemButton> */}
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 9 }}
            component={Link}
            to="/admin/users/administrator"
            selected={location.pathname.startsWith(
              "/admin/users/administrator"
            )}
          >
            <ListItemText primary="Administrator" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 9 }}
            component={Link}
            to="/admin/users/scanner"
            selected={location.pathname.startsWith("/admin/users/scanner")}
          >
            <ListItemText primary="Scanner" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton
        component={Link}
        to="/admin/loyalty"
        selected={location.pathname.startsWith("/admin/loyalty")}
      >
        <ListItemIcon>
          <RedeemIcon />
        </ListItemIcon>
        <ListItemText primary="Loyalty" />
      </ListItemButton>
      <ListItemButton
        component={Link}
        to="/admin/notification"
        selected={location.pathname.startsWith("/admin/notification")}
      >
        <ListItemIcon>
          <NotificationsNoneIcon />
        </ListItemIcon>
        <ListItemText primary="Notification" />
      </ListItemButton>
      {/* <ListItemButton component={Link} to="/admin/events">
                <ListItemIcon>
                    <EventIcon />
                </ListItemIcon>
                <ListItemText primary="Events" />
            </ListItemButton> */}
      {/* <ListItemButton>
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Integrations" />
        </ListItemButton> */}
    </React.Fragment>
  );
}

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
