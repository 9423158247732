import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useRouterUtil } from "utils/router";
import {
  Box,
  Paper,
  Tooltip,
  IconButton,
  TableCell,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataTable, {
  IHeadCell,
  IDataTableRow,
} from "components/Common/DataTable";
import DataActionsMenu from "components/Common/DataActionsMenu";
import {
  loadUsers,
  selectUsers,
  selectUserPageCount,
  selectTotalCount,
} from "modules/users/store/usersSlice";
import UserService from "modules/users/services/userService";
import Loading from "components/Common/Loading";
import { ConfirmModal } from "components/Common";
import { IUser } from "modules/users/types";

const headCells: IHeadCell[] = [
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "Full name",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Phone number",
  },
  {
    id: "roles",
    numeric: false,
    disablePadding: false,
    label: "Roles",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function ToolBar(props: any) {
  const { gotoLink } = useRouterUtil();

  return (
    <>
      <Tooltip title="Add User">
        <IconButton
          onClick={(event) => gotoLink(event, `/admin/users/${props.role}/add`)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

function UserRow(props: IDataTableRow) {
  return (
    props.item && (
      <>
        <TableCell
          component="th"
          id={props.labelId}
          scope="row"
          sx={{ maxWidth: "200px", wordBreak: "break-word" }}
        >
          {props.item?.username}
        </TableCell>
        <TableCell sx={{ maxWidth: "200px", wordBreak: "break-word" }}>
          {props.item?.fullName}
        </TableCell>
        <TableCell>{props.item?.phoneNumber}</TableCell>
        <TableCell>{props.item?.roles[0]}</TableCell>
        <TableCell>
          {props.item?.status === "Active" ? (
            <Chip label="Active" color="primary" size="small" />
          ) : (
            <Chip label="Inactive" size="small" />
          )}
        </TableCell>
        <TableCell align="right">
          <DataActionsMenu
            onDelete={() => props.onDelete && props.onDelete(props.item)}
            onUpdate={() => props.onUpdate && props.onUpdate(props.item)}
          />
        </TableCell>
      </>
    )
  );
}

export default function UserPage() {
  let { role } = useParams();
  const dispatch = useAppDispatch();
  const { navigate } = useRouterUtil();

  const users = useAppSelector(selectUsers);
  const userPageCount = useAppSelector(selectUserPageCount);
  const totalUsers = useAppSelector(selectTotalCount);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [userSelected, setUserSelected] = useState<IUser>();
  const [paging, setPagingData] = useState({
    total: 0,
    pages: 1,
    page: 1,
    limit: 15,
  });

  const getUsers = (
    payload: {
      roleNames?: string;
    } = {
      roleNames: role,
    }
  ) => {
    dispatch(
      loadUsers({
        ...payload,
        pageNumber: paging.page,
        pageSize: paging.limit,
      })
    );
  };

  const onClickDeleteItem = (user: IUser) => {
    setUserSelected(user);
    setConfirmMessage(`Are you sure want to delete ${user?.username}?`);
    setOpenConfirmModal(true);
  };

  const confirmModal = () => {
    if (userSelected) {
      UserService.deleteUser(userSelected?.username)
        .then(() => {
          setUserSelected(undefined);
          setConfirmMessage("Delete user successfully !!!");
          setOpenConfirmModal(true);
          getUsers();
        })
        .catch(() => {
          setUserSelected(undefined);
          setConfirmMessage("System error, please try again later");
          setOpenConfirmModal(true);
        });
    } else {
      setOpenConfirmModal(false);
    }
  };

  const onPageChange = (page: number) => {
    setPagingData({
      ...paging,
      page,
    });
  };

  const onClickEditItem = (user: IUser) => {
    navigate(`/admin/users/${role}/${user?.username}`);
  };

  const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (paging.page !== 1) {
      setPagingData({
        ...paging,
        page: 1,
      });
    } else {
      getUsers();
    }
  }, [role]);

  useEffect(() => {
    getUsers();
  }, [paging.page]);

  useEffect(() => {
    const pageData = {
      ...paging,
      total: users?.totalCount || 0,
    };

    setPagingData({
      ...pageData,
      pages: Math.ceil(pageData.total / pageData.limit),
      page:
        userPageCount === 0 && pageData.page > 1
          ? pageData.page - 1
          : pageData.page,
    });
  }, [totalUsers]);

  if (!users) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <DataTable
            headCells={headCells}
            rows={users.results}
            paging={paging}
            name="username"
            title={role ? capitalizeFirst(role) : "Users"}
            toolbarActions={<ToolBar role={role ? role : "administrator"} />}
            onPageChange={onPageChange}
            onDelete={onClickDeleteItem}
            onUpdate={onClickEditItem}
          >
            <UserRow />
          </DataTable>
        </Paper>
      </Box>

      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        content={confirmMessage}
        onConfirm={confirmModal}
        enableCancel={userSelected ? true : false}
        backdropClick={userSelected ? true : false}
      />
    </>
  );
}
