import UserPage from "modules/users/pages/UserPage";
import AddUserPage from "modules/users/pages/AddUserPage";
import EditUserPage from "modules/users/pages/EditUserPage";

const router = [
  {
    path: "users",
    children: [
      {
        path: ":role",
        element: <UserPage />,
        loader: () => ({ title: "Users" }),
      },
      {
        path: ":role/add",
        element: <AddUserPage />,
        loader: () => ({ title: "Users" }),
      },
      {
        path: ":role/:username",
        element: <EditUserPage />,
        loader: () => ({ title: "Users" }),
      },
    ],
  },
];

export default router;
