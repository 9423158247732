import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export function ConfirmModal(props: any) {
  const { title, content, children, open, setOpen, enableCancel, backdropClick, onConfirm } = props;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(_e, reason) => {
        if (backdropClick && reason === 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ wordBreak: "break-word" }}>{content}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        {enableCancel && <Button variant="outlined" onClick={onClose}>Cancel</Button>}
        <Button
          variant="contained" color="primary" type="submit"
          autoFocus
          onClick={() => {
            onConfirm();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
