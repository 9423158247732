import axiosClient from "utils/axiosClient.util"

const LoyaltyService = {
  exportLuckyDrawCoupons(params: any): Promise<any> {
    return axiosClient.post("api/Loyalty/v1/export-lucky-draw-coupons", { ...params })
  },
  exportAuditReport(params: any): Promise<any> {
    return axiosClient.post("api/Loyalty/v1/luckydraw/export", { ...params })
  },
  exportAddressReport(params: any): Promise<any> {
    return axiosClient.post("api/Loyalty/v1/luckydraw/export-address", { ...params })
  },
  importLuckyDrawWinner(params: any): Promise<any> {
    return axiosClient.post("api/Loyalty/v1/import-lucky-draw-winner", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
}

export default LoyaltyService
