import { MouseEvent } from "react";
import { useNavigate } from 'react-router-dom';

export const useRouterUtil = function () {
    const navigate = useNavigate();
    const gotoLink = (event: MouseEvent, link: string) => {
        event.preventDefault();
        navigate(link)
    }
    const goBack = () => {
		navigate(-1);
	}

    return {
        navigate,
        gotoLink,
        goBack
    }
}