import StoryBlokPage from "modules/demo/pages/StoryBlok";

const router = [
  {
    path: "demo",
    children: [
      {
        path: "/admin/demo/storyblok",
        element: <StoryBlokPage />,
        loader: () => ({ title: "StoryBlok" }),
      },
    ],
  },
];

export default router;
