import { useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useRouterUtil } from "utils/router";
import ExportLuckyDrawCoupons from "./ExportTheLuckyDrawCoupons";
import ExportTheAuditReport from "./ExportTheAuditReport";
import ExportTheAddressReport from "./ExportTheAddressReport";
import ImportTheLuckyDrawWinner from "./ImportTheLuckyDrawWinner";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "30px 0" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function LoyaltyPage() {
  const { goBack } = useRouterUtil();

  const [tabsActive, setTabsActive] = useState(0);

  const handleTabsChange = (_event: React.SyntheticEvent, index: number) => {
    setTabsActive(index);
  };

  return (
    <>
      <Paper sx={{ width: '100%', p: 2 }}>
        <div className="pg-header">
          <ArrowBackIos className="arrow-back" onClick={goBack} />
          <Typography className="pg-header__title" variant="h6">
            Loyalty
          </Typography>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabsActive}
            onChange={handleTabsChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabs-custom"
          >
            <Tab label="Export the lucky draw coupons" {...a11yProps(0)} />
            <Tab label="Export the audit report" {...a11yProps(1)} />
            <Tab label="Export the address report" {...a11yProps(2)} />
            <Tab label="Import the lucky draw winner" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={tabsActive} index={0}>
          <ExportLuckyDrawCoupons />
        </TabPanel>
        <TabPanel value={tabsActive} index={1}>
          <ExportTheAuditReport />
        </TabPanel>
        <TabPanel value={tabsActive} index={2}>
          <ExportTheAddressReport />
        </TabPanel>
        <TabPanel value={tabsActive} index={3}>
          <ImportTheLuckyDrawWinner />
        </TabPanel>
      </Paper>
    </>
  )
}
